import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Union({ styles, ...props }) {
  const Elements = useComponents()

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page", { backgroundColor: "#ffffff" })}
    >
      <div
        className="formkit-page-header"
        style={styles.forElement("page_header")}
      >
        <div className="formkit-background-overlay" />
        <div className="formkit-container formkit-logo">
          <Elements.Image
            className="formkit-image"
            name="image"
            size={{ h: 100, w: 100 }}
          />
        </div>
        <div className="formkit-container">
          <div className="formkit-column">
            <Elements.Heading
              className="formkit-header"
              name="header"
              defaults={{ content: "Enter a catchy page headline" }}
            />
            <Elements.Region name="content" className="formkit-content">
              <Elements.Content
                defaults={{
                  content: "Provide some more detail in this subtitle"
                }}
              />
            </Elements.Region>
          </div>

          <div className="formkit-column">
            <Elements.Form
              className="formkit-card"
              style={styles.forElement("form", {
                backgroundColor: "#ffffff"
              })}
            >
              <Elements.Errors />
              <Elements.CustomFields>
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Subscribe" }}
                />
              </Elements.CustomFields>
              <Elements.Content
                name="disclaimer"
                defaults={{
                  content: "We respect your privacy. Unsubscribe at any time."
                }}
              />
            </Elements.Form>
          </div>
        </div>
      </div>
      <div className="formkit-footer">
        <div className="formkit-container">
          <Elements.Content
            name="copyright"
            defaults={{
              content: `All rights reserved`
            }}
          />
          <Elements.BuiltWith variant="dark" />
        </div>
      </div>
    </Elements.LandingPage>
  )
}

Union.style = "landing_page"
Union.categories = ["Product", "Waitlist"]
Union.thumbnail = ""
Union.preview = "https://demo.ck.page/union"
Union.fields = [
  {
    name: "first_name",
    label: "Your first name"
  },
  {
    name: "email_address",
    label: "Your email address"
  }
]

export default createTemplate(Union, { name: "Union" })
